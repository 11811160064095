// ----------------------------------------------------------------------

export function localStorageAvailable() {
    try {
        const key = "__some_random_key_you_are_not_going_to_use__"
        window.localStorage.setItem(key, key)
        window.localStorage.removeItem(key)
        return true
    } catch (error) {
        return false
    }
}

export function localStorageGetItem(key: string, defaultValue = "") {
    const storageAvailable = localStorageAvailable()

    let value

    if (storageAvailable) {
        value = localStorage.getItem(key) || defaultValue
    }

    return value
}

export const getItemFromLocalStorage = (key: string) => {
    try {
        return key ? localStorage.getItem(key) : null
    } catch (error) {
        return null
    }
}

export const isJobDetails = () => {
    const url = window.location.href
    return !!url?.match(/jobDetails\/([a-f0-9-]+)/i)?.[1] || !!url?.match(/jobDetailsV2\/([a-f0-9-]+)/i)?.[1]
}
