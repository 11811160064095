import type { Dayjs, OpUnitType } from "dayjs"

import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"

// ----------------------------------------------------------------------

dayjs.extend(duration)
dayjs.extend(relativeTime)

// ----------------------------------------------------------------------

export type DatePickerFormat = Dayjs | Date | string | number | null | undefined

/**
 * Docs: https://day.js.org/docs/en/display/format
 */
export const formatStr = {
    dateTime: "DD MMM YYYY h:mm a", // 17 Apr 2022 12:00 am
    date: "DD MMM YYYY", // 17 Apr 2022
    time: "h:mm a", // 12:00 am
    split: {
        dateTime: "DD/MM/YYYY h:mm a", // 17/04/2022 12:00 am
        date: "DD/MM/YYYY", // 17/04/2022
    },
    paramCase: {
        dateTime: "DD-MM-YYYY h:mm a", // 17-04-2022 12:00 am
        date: "DD-MM-YYYY", // 17-04-2022
    },
}

export function today(format?: string) {
    return dayjs(new Date()).startOf("day").format(format)
}

// ----------------------------------------------------------------------

/** output: 17 Apr 2022 12:00 am
 */
export function fDateTime(date: DatePickerFormat, format?: string) {
    if (!date) {
        return null
    }

    const isValid = dayjs(date).isValid()

    return isValid ? dayjs(date).format(format ?? formatStr.dateTime) : "Invalid time value"
}

// ----------------------------------------------------------------------

/** output: 17 Apr 2022
 */
export function fDate(date: DatePickerFormat, format?: string) {
    if (!date) {
        return null
    }

    const isValid = dayjs(date).isValid()

    return isValid ? dayjs(date).format(format ?? formatStr.date) : "Invalid time value"
}

// ----------------------------------------------------------------------

/** output: 12:00 am
 */
export function fTime(date: DatePickerFormat, format?: string) {
    if (!date) {
        return null
    }

    const isValid = dayjs(date).isValid()

    return isValid ? dayjs(date).format(format ?? formatStr.time) : "Invalid time value"
}

export const secondsToFormattedDuration = (secondsDuration: number | undefined) => {
    if (!secondsDuration) return "0:0"

    secondsDuration = Math.round(secondsDuration)
    const formatter = new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 1 })
    const mins = Math.floor(secondsDuration / 60)
    const secs = secondsDuration % 60
    return `${formatter.format(mins)}:${formatter.format(secs)}`
}

// ----------------------------------------------------------------------

/** output: 1713250100
 */
export function fTimestamp(date: DatePickerFormat) {
    if (!date) {
        return null
    }

    const isValid = dayjs(date).isValid()

    return isValid ? dayjs(date).valueOf() : "Invalid time value"
}

// ----------------------------------------------------------------------

/** output: a few seconds, 2 years
 */
export function fToNow(date: DatePickerFormat) {
    if (!date) {
        return null
    }

    const isValid = dayjs(date).isValid()

    return isValid ? dayjs(date).toNow(true) : "Invalid time value"
}

// ----------------------------------------------------------------------

/** output: boolean
 */
export function fIsBetween(inputDate: DatePickerFormat, startDate: DatePickerFormat, endDate: DatePickerFormat) {
    if (!inputDate || !startDate || !endDate) {
        return false
    }

    const formattedInputDate = fTimestamp(inputDate)
    const formattedStartDate = fTimestamp(startDate)
    const formattedEndDate = fTimestamp(endDate)

    if (formattedInputDate && formattedStartDate && formattedEndDate) {
        return formattedInputDate >= formattedStartDate && formattedInputDate <= formattedEndDate
    }

    return false
}

// ----------------------------------------------------------------------

/** output: boolean
 */
export function fIsAfter(startDate: DatePickerFormat, endDate: DatePickerFormat) {
    return dayjs(startDate).isAfter(endDate)
}

// ----------------------------------------------------------------------

/** output: boolean
 */
export function fIsSame(startDate: DatePickerFormat, endDate: DatePickerFormat, units?: OpUnitType) {
    if (!startDate || !endDate) {
        return false
    }

    const isValid = dayjs(startDate).isValid() && dayjs(endDate).isValid()

    if (!isValid) {
        return "Invalid time value"
    }

    return dayjs(startDate).isSame(endDate, units ?? "year")
}

// ----------------------------------------------------------------------

/** output:
 * Same day: 26 Apr 2024
 * Same month: 25 - 26 Apr 2024
 * Same month: 25 - 26 Apr 2024
 * Same year: 25 Apr - 26 May 2024
 */
export function fDateRangeShortLabel(startDate: DatePickerFormat, endDate: DatePickerFormat, initial?: boolean) {
    const isValid = dayjs(startDate).isValid() && dayjs(endDate).isValid()

    const isAfter = fIsAfter(startDate, endDate)

    if (!isValid || isAfter) {
        return "Invalid time value"
    }

    let label = `${fDate(startDate)} - ${fDate(endDate)}`

    if (initial) {
        return label
    }

    const isSameYear = fIsSame(startDate, endDate, "year")
    const isSameMonth = fIsSame(startDate, endDate, "month")
    const isSameDay = fIsSame(startDate, endDate, "day")

    if (isSameYear && !isSameMonth) {
        label = `${fDate(startDate, "DD MMM")} - ${fDate(endDate)}`
    } else if (isSameYear && isSameMonth && !isSameDay) {
        label = `${fDate(startDate, "DD")} - ${fDate(endDate)}`
    } else if (isSameYear && isSameMonth && isSameDay) {
        label = `${fDate(endDate)}`
    }

    return label
}

// ----------------------------------------------------------------------

export type DurationProps = {
    years?: number
    months?: number
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
    milliseconds?: number
}

/** output: '2024-05-28T05:55:31+00:00'
 */
export function fAdd({
    years = 0,
    months = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0,
}: DurationProps) {
    const result = dayjs()
        .add(
            dayjs.duration({
                years,
                months,
                days,
                hours,
                minutes,
                seconds,
                milliseconds,
            })
        )
        .format()

    return result
}

/** output: '2024-05-28T05:55:31+00:00'
 */
export function fSub({
    years = 0,
    months = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0,
}: DurationProps) {
    const result = dayjs()
        .subtract(
            dayjs.duration({
                years,
                months,
                days,
                hours,
                minutes,
                seconds,
                milliseconds,
            })
        )
        .format()

    return result
}

/**
 * Takes a number of months, and converts it into a string containing months and years. Ex: 2yrs 5 mos
 * @param timeInMonths The number of months to format
 */
export const monthsToString = (timeInMonths: number | undefined) => {
    if (!timeInMonths) return undefined

    const years = Math.floor(timeInMonths / 12)
    const months = Math.floor(timeInMonths % 12)

    const yearsLabel = years === 1 ? "yr" : "yrs"
    const monthsLabel = months === 1 ? "mo" : "mos"

    if (years === 0 && months === 0) return undefined
    if (years === 0) return `${months} ${monthsLabel}`
    if (months === 0) return `${years} ${yearsLabel}`
    return `${years} ${yearsLabel} ${months} ${monthsLabel}`
}

export const timeStringToSeconds = (timeString: string) => {
    const parts = timeString.split(":").map(Number)
    const [seconds = 0, minutes = 0, hours = 0] = parts.reverse()
    return hours * 3600 + minutes * 60 + seconds
}
