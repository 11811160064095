import axios, { endpoints } from "src/utils/axios"

import { setSession } from "./utils"

// ----------------------------------------------------------------------

export type SignInParams = {
    email: string
    password: string
}

export type SignUpParams = {
    email: string
    password: string
    firstName: string
    lastName: string
    phoneNumber: string
}

/** **************************************
 * Sign in
 *************************************** */
export const signInWithPassword = async ({ email, password }: SignInParams): Promise<void> => {
    try {
        const params = { email, password }

        const res = await axios.post(endpoints.auth.signIn, params)

        const token = res.data?.data?.token
        const newRefreshToken = res?.data?.data?.refreshToken

        if (!token) {
            throw new Error("Access token not found in response")
        }

        localStorage.setItem("jwt_refresh_token", newRefreshToken)
        setSession(token)
    } catch (error) {
        console.error("Error during sign in:", error)
        throw error
    }
}

export const signInWithGoogle = async (credential: string): Promise<void> => {
    try {
        localStorage.removeItem("jwt_refresh_token")
        const res = await axios.get(`${endpoints.auth.googleLogin}?token=${credential}`)

        const token = res.data?.data?.token

        if (!token) {
            throw new Error("Access token not found in response")
        }

        setSession(token)
    } catch (error) {
        console.error("Error during sign in:", error)
        throw error
    }
}

export const signInWithMicrosoft = async (credential: string, redirectUri: string): Promise<void> => {
    try {
        localStorage.removeItem("jwt_refresh_token")
        const res = await axios.post(endpoints.auth.msLogin, {
            code: credential,
            redirectUri,
        })

        const token = res.data?.data?.token

        if (!token) {
            throw new Error("Access token not found in response")
        }

        setSession(token)
    } catch (error) {
        console.error("Error during sign in:", error)
        throw error
    }
}

/** **************************************
 * Sign up
 *************************************** */
export const signUp = async ({ email, password, firstName, lastName, phoneNumber }: SignUpParams): Promise<void> => {
    const params = {
        email,
        password,
        fullName: `${firstName} ${lastName}`,
        phoneNumber,
    }

    try {
        await axios.post(endpoints.auth.signUp, params)
    } catch (error) {
        console.error("Error during sign up:", error)
        throw error
    }
}

/** **************************************
 * Sign out
 *************************************** */
export const signOut = async (): Promise<void> => {
    try {
        await setSession(null)
    } catch (error) {
        console.error("Error during sign out:", error)
        throw error
    }
}
